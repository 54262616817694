import { Sentry, mixpanel, analytics } from '../lib/analytics';

var jquery = require("jquery");
$ = window.$ = window.jQuery = jquery; // notice the definition of global variables here

import LearnerPlusTranscriptFeatureImagePath from '../img/LearnerPlusTranscriptFeature.png';
import LearnerPlusWhatsAppQRCode from '../img/learnerplus-app-whatsapp-qr.png';
import LearnerPlusWhatsAppQRCode_GPTrainees from '../img/W3EOZ5ATX6LFO1.png';

import axios from 'axios';

import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'
const { toggle } = createPopup('<form-id>');

//
const Mustache = require('mustache');
import cpdImagePath from '../img/cpd/c.Certified-highres-trns.png';
//
import firebase from "firebase/compat/app";

import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/database";
import "firebase/compat/firestore";

import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

// import our utils
import { getSmartRecurringReminderPacket } from '../lib/reminderutils';
import { getQueryParamsAsObject, getQueryParamsAsString, removeURLParameters } from "../lib/util-standardfunctions";
import { doc } from 'firebase/firestore';


//
if(window.document.documentMode){
    document.getElementById('main-preloader').innerHTML = '<div class="col s8 offset-s2"><h3>This browser is not supported. Please use Apple Safari, Mozilla Firefox, Microsoft Edge or Google Chrome</h3></div>';
}
if (window.location.href.indexOf("http://localhost") === -1){
    window.console.log = function(){
        console.info('© 2024 SCAD.Ventures');
        window.console.log = function() {
            return false;
        }
    }
}

!(function() {
    var CURR_USER = false;
    var CURR_USERPROFILE = false;
    var CURR_STRIPESTATUS = false;
    var CURR_STRIPEPACKET = false;
    var CURR_ENTERPRISEMODE_IS_ACTIVE = false; // if enterprise mode was detected
    var CURR_ENTERPRISEMODE_STATUS = ""; // message for enterprise mode
    var CURR_LEARNERSTATE = {
        creditBalance: 0,
        reflections: [],
        reflection_ids: [],
        certificates: []
    }
    var CURR_REFLECTIONKIT = false;
    //
    var CURR_QueryParams_Str = getQueryParamsAsString();
    var CURR_QueryParams_Obj = getQueryParamsAsObject();
    console.log("CURR_QueryParams_Obj", CURR_QueryParams_Obj);
    // add a function that checks and removes the console if the URL is not localhost
    removeURLParameters(Object.keys(CURR_QueryParams_Obj));
    console.log("MIJI JSKJM!");
    console.log("JSK JM MIJI");
    //
    const firebaseConfig = {
        apiKey: "AIzaSyAkOAXn8FEH1PKjf_aghihKEamNddBQB18",
        authDomain: "learner-plus.firebaseapp.com",
        projectId: "learner-plus",
        storageBucket: "learner-plus.appspot.com",
        messagingSenderId: "812434383550",
        appId: "1:812434383550:web:d2cda1285d3e3199a134ef"
    };
    
    // Initialize Firebase
    showLoadingOverlay("Loading Learner+");
    //
    console.log("%cLearner+ vs Learner Hub free -- we need to hide reflect now button for non-subscribers (stripe check)", "color:red; background:white; font-size:1.26em;")
    //
    const app = firebase.initializeApp(firebaseConfig);
    firebase.auth().onAuthStateChanged((user)=>{
        console.log(user);
        if (user){
            // we have a user
            console.log(user.uid);
            UI_BindActions();
            //
            CURR_USER = user;
            // we see if the profile is filled out otherwise move forward
            firebase.firestore().collection('userprofiles').doc(user.uid).get().then((doc)=>{
                if (doc.exists){
                    showLoadingOverlay("Loading your dashboard, certificates and more...");
                    CURR_USERPROFILE = doc.data();
                    CURR_REFLECTIONKIT = "reflective-practice";
                    if (CURR_USERPROFILE.reflectionkits){
                        CURR_REFLECTIONKIT = CURR_USERPROFILE.reflectionkits[0]?CURR_USERPROFILE.reflectionkits[0]: "reflective-practice";                    
                    }
                    //
                    Sentry.setContext("CURR_USER", CURR_USER);
                    Sentry.setContext("CURR_USERPROFILE", CURR_USERPROFILE);
                    Sentry.setContext("CURR_REFLECTIONKIT", CURR_REFLECTIONKIT);
                    //
                    mixpanel.identify(CURR_USER.uid);
                    mixpanel.people.set({
                        "$email": CURR_USERPROFILE.email,
                        "$name": CURR_USERPROFILE.firstname + " " + CURR_USERPROFILE.lastname,
                        "credential": CURR_USERPROFILE.credential,
                    });
                    mixpanel.track('Learner+ Hub Visit', {CURR_QueryParams_Obj});
                    //
                    document.getElementById('user-phonenumber').innerHTML = CURR_USER.phoneNumber;
                    document.getElementById('user-email').innerHTML = CURR_USERPROFILE.email;
                    //
                    CURR_STRIPESTATUS = false;
                    CURR_ENTERPRISEMODE_IS_ACTIVE = false;
                    CURR_ENTERPRISEMODE_STATUS = "";
                    //
                    fetchStripeStatus(()=>{
                        mixpanel.track('Fetch Enterprise and Stripe Status', {CURR_STRIPESTATUS, CURR_ENTERPRISEMODE_IS_ACTIVE, CURR_ENTERPRISEMODE_STATUS});
                        InitiateMainApp();
                    });
                    
                }else{
                    console.log("issue with doc in the userprofiles section");
                    window.location.href = '/login?go=hub';
                }
            }).catch((error)=>{
                showNotification('Profile Fetch Error', 'There was an error fetching your profile - '+error+'.', 'error', 3000);
                window.location.href = '/login?go=hub';
            });
            //
        }else{
            // no user
            console.log("issue with user logged in");
            var includeSubscriptionHook = '';
            if (CURR_QueryParams_Obj.navigate_to_subscribe){
                includeSubscriptionHook = '&navigate_to_subscribe=true';
            }
            window.location.href = `/login?go=hub${includeSubscriptionHook}`;
        }
    });

    function fetchStripeStatus(callback){
        firebase.firestore().collection('stripe').doc(CURR_USER.uid).get().then((doc)=>{
            if (doc.data()){
                var stripePacket = doc.data();
                CURR_STRIPEPACKET = stripePacket;
                if (stripePacket.subscription_status === 'active'){
                    CURR_STRIPESTATUS = true;
                    return callback();
                }else{
                    return checkEnterpriseStripeOverride(callback);
                }
            }else{
                return checkEnterpriseStripeOverride(callback);
            }
        }).catch(stripeErr=>{
            Sentry.captureException(stripeErr);
            CURR_STRIPESTATUS = false;
            callback();
        });
    }

    // we check email in domain OR actual email in roster! :) 
    function checkEnterpriseStripeOverride(callback) {
        console.log('checking check-enterprise-membership')
        axios.post(`/check-enterprise-membership`,{email: CURR_USERPROFILE.email})
            .then((response) => {
                const { status, team, expirationDate } = response.data;
                if (status === "active") {
                    CURR_STRIPESTATUS = true;
                    CURR_ENTERPRISEMODE_IS_ACTIVE = true;
                    CURR_ENTERPRISEMODE_STATUS = `Team: ${team}`;
                } else if (status === "expired") {
                    CURR_STRIPESTATUS = false;
                    CURR_ENTERPRISEMODE_IS_ACTIVE = true;
                    CURR_ENTERPRISEMODE_STATUS = `Team: ${team} | Seat expired (${expirationDate})`;
                } else {
                    CURR_STRIPESTATUS = false;
                    CURR_ENTERPRISEMODE_IS_ACTIVE = false;
                    CURR_ENTERPRISEMODE_STATUS = "";
                }
                return callback();
        }).catch((error) => {
            console.error("Error checking enterprise membership:", error);
            CURR_STRIPESTATUS = false;
            CURR_ENTERPRISEMODE_IS_ACTIVE = false;
            CURR_ENTERPRISEMODE_STATUS = "";
            return callback();
        });
    }
      
    function checkEnterpriseStripeOverride_OLD(callback){
        try{
            var userEmailDomain = CURR_USERPROFILE.email.split('@')[1].toLowerCase();
            console.log("checkEnterpriseStripeOverride - "+userEmailDomain);
            firebase.firestore().collection('enterpriseroster').where('domain', '==', userEmailDomain).get().then((querySnapshot)=>{
                if (querySnapshot.docs.length >= 1){ // only the first enterprise should cover this
                    return validateTeamOrEnterpriseMembership(querySnapshot, callback);
                }else{
                    firebase.firestore().collection('enterpriseroster').where('roster', 'array-contains', CURR_USERPROFILE.email.toLowerCase()).get().then((rosterQuerySnapshot)=>{
                        if (rosterQuerySnapshot.docs.length >= 1){
                            return validateTeamOrEnterpriseMembership(rosterQuerySnapshot, callback);
                        }else{
                            CURR_STRIPESTATUS = false;
                            CURR_ENTERPRISEMODE_IS_ACTIVE = false; // enterprise mode is not detected
                            CURR_ENTERPRISEMODE_STATUS = "";
                            return callback();
                        }
                    });
                }
            });
        }catch(someErr){
            return callback();
        }
    }

    function validateTeamOrEnterpriseMembership(querySnapshot, callback){
        var enterpriseDocReference = querySnapshot.docs[0].data();
        var enterpriseDocReferenceId = querySnapshot.docs[0].id;
        console.log(enterpriseDocReference, enterpriseDocReferenceId);
        var todaysDate = (new Date()).getTime();
        var expirationDate = (new Date(enterpriseDocReference.expire_on.seconds*1000)).getTime();
        // lets update the roster if the name is not present
        if (enterpriseDocReference.roster.includes(CURR_USERPROFILE.email.toLowerCase())){
            // we are good to go
        }else{
            // we need to update the roster and save the document
            enterpriseDocReference.roster.push(CURR_USERPROFILE.email.toLowerCase());
            firebase.firestore().collection('enterpriseroster').doc(enterpriseDocReferenceId).update({
                roster:enterpriseDocReference.roster
            });
        }
        if (todaysDate > expirationDate){
            CURR_STRIPESTATUS = false;
            CURR_ENTERPRISEMODE_IS_ACTIVE = true; // enterprise mode is detected; but seat might be expired
            CURR_ENTERPRISEMODE_STATUS = "Team: " + enterpriseDocReference.client_name + " | Seat expired ("+(new Date(enterpriseDocReference.expire_on.seconds*1000)).toLocaleDateString()+")";
            return callback();
        }else{
            CURR_STRIPESTATUS = true;
            CURR_ENTERPRISEMODE_IS_ACTIVE = true; // enterprise mode is detected; and seat is active
            CURR_ENTERPRISEMODE_STATUS = "Team: " + enterpriseDocReference.client_name;
            return callback();
        }
    }

    function setRewardful(){
        try{
            if (CURR_QueryParams_Obj.subscription === "success"){
                rewardful('ready', function() {
                    if(Rewardful.referral) {
                        // The current website visitor is a referral from an affiliate.
                        rewardful('convert', { email: CURR_USERPROFILE.email})
                    }
                });
            }
        }catch(ex){
            
        }
    }

    const mocToggleBtn = document.getElementById('toggleButton');
    const myMocSection = document.getElementById('my_moc_section');
    const toggleCEBrokerButton = document.getElementById('toggleCEBroker');
    const myCEBrokerSection = document.getElementById('my_cebroker');
    //    

    function initMOCandCEBrokerWidgets(){
        try{
            // event ties
            mocToggleBtn.addEventListener('click', () => {
                if (myMocSection.style.maxHeight) {
                    myMocSection.style.maxHeight = null;
                } else {
                    myMocSection.style.maxHeight = myMocSection.scrollHeight + 'px';
                }
            });
                                
            toggleCEBrokerButton.addEventListener('click', () => {
            if (myCEBrokerSection.style.maxHeight) {
                    myCEBrokerSection.style.maxHeight = null;
                } else {
                    myCEBrokerSection.style.maxHeight = myCEBrokerSection.scrollHeight + 'px';
                }
            });
            //
            if (CURR_STRIPESTATUS){
                var birthdateInput = document.getElementById('moc-dob');

                birthdateInput.addEventListener('input', (event) => {
                    let value = event.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
                    if (value.length >= 2) value = value.slice(0, 2) + '/' + value.slice(2); // Insert slash
                    if (value.length > 5) value = value.slice(0, 5); // Limit to MM/DD
                    event.target.value = value;
                });

                birthdateInput.addEventListener('blur', (event) => {
                    const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/; // MM/DD format validation
                    if (!regex.test(event.target.value)) {
                        alert('Please enter a valid date in MM/DD format.');
                        event.target.focus();
                    }
                });

            }
        }catch(ex){
            console.log("This function is not called because we're in an unsubcribed mode");
        }

    }

    var FOURTEEN_FISH_SMART_DEEP_LINK = false;
    function CheckAndDo_SmartTasksOnLoad(){
        // let's check if Fourteen Fish smart task is in the URL
        // /?go=hub&task=fourteenfishsmartsync&fourteenfishdeeplink=
        if (CURR_QueryParams_Obj.task === "fourteenfishsmartsync" && CURR_QueryParams_Obj.fourteenfishdeeplink){
            if (CURR_USERPROFILE.fourteenfish){
                FOURTEEN_FISH_SMART_DEEP_LINK = CURR_QueryParams_Obj.fourteenfishdeeplink;
                performTask('fourteenfishsmartsync');
            }else{
                showValidationModal(`Link your Learner+ account with FourteenFish`, `Connect your Learner+ account with FourteenFish to easily sync your reflections to your portfolio.`, "Connect", ()=>{
                    performTask('connect-fourteen-fish');
                }, "Not now", ()=>{
                    window.close();
                });
            }
        }
    }

    function FetchDeepLinkAndSyncWithFourteenFish(){
        // first fetch it
        axios.get(`/agent/fourteenfish/fetch-smart-deep-link/${FOURTEEN_FISH_SMART_DEEP_LINK}`).then((response)=>{
            if (response.data.error){
                alert(response.data.error);
            }else{
                // we have the data
                var fourteenFishEntry = response.data;
                var CURR_FourteenFish_EntriesToSync = [fourteenFishEntry];
                console.log(CURR_FourteenFish_EntriesToSync);
                var FF_userId = CURR_USERPROFILE.fourteenfish.userId;
                var FF_userApiKey = CURR_USERPROFILE.fourteenfish.userApiKey;
                //
                axios.post(`/auth/fourteenfish/report`, 
                    {
                        userId: FF_userId,
                        userApiKey: FF_userApiKey,
                        entries: CURR_FourteenFish_EntriesToSync
                    }
                ).then((response)=>{
                    if (!response.data.success){
                        return showNotification('FourteenFish Sync Error', 'There was an error syncing your reflection to FourteenFish - '+response.data.message+'.', 'error', 4500);
                    }
                    axios.get(`/agent/fourteenfish/update-smart-deep-link/${FOURTEEN_FISH_SMART_DEEP_LINK}?uid=${CURR_USER.uid}`).then((response)=>{
                        //showNotification('Synced with FourteenFish!', 'Your reflection has been synced to your FourteenFish account. You may close this browser tab now.', 'success', 4500);
                        showValidationModal('FourteenFish Synced', 'Your reflection has been synced to your FourteenFish account.', "Return to chat", ()=>{
                            window.close();
                        }, "View on FourteenFish", ()=>{
                            window.location.href = 'https://www.fourteenfish.com/entries';
                        });
                    }).catch((error)=>{
                        console.error(error);
                        showNotification('FourteenFish Sync Error', 'There was an error syncing your reflection to FourteenFish - '+error+'.', 'error', 4500);
                    })
                }).catch((error)=>{
                    console.error(error);
                    showNotification('FourteenFish Sync Error', 'There was an error syncing your reflection to FourteenFish - '+error+'.', 'error', 4500);
                });
            }
        });
    }

    function InitiateMainApp(){
        //
        document.getElementById('mainApp').classList.remove('hidden');
        hideLoadingOverlay();
        // we should fetch the user
        setProfileUI();
        RefineViewForReflectionKit();
        // Persona-CPD-specific stuff
        setPersonaSpecificUI();
        //
        // let's update the stripe settings
        if (!CURR_STRIPESTATUS){
            console.log("Not a stripe subscriber");
            document.querySelectorAll('.subscribed-element').forEach((element)=>{
                element.remove();
            });
            // ensure the message shows up if enterprise mode is active
            document.querySelector('#subscription-status-btn').innerHTML = "Upgrade your account";
        }else{
            // subscriber
            document.querySelectorAll('.unsubscribed-element').forEach((element)=>{
                element.remove();
            });
            // now we need to see what's the enterprise hook (we don't show subscription option for these folks)
            if (CURR_ENTERPRISEMODE_IS_ACTIVE){
                // enterprise mode is activity 
                document.querySelector('#subscription-status-btn').innerHTML = "Leave Team (Subscribe individually)";
            }else{
                document.querySelector('#subscription-status-btn').innerHTML = "Manage Subscription";
            }
        }
        // label enterprise/team mode
        try{
            if (CURR_ENTERPRISEMODE_IS_ACTIVE){
                document.getElementById('enterpriseteammode_landing_status').innerHTML = CURR_ENTERPRISEMODE_STATUS;
                document.getElementById('enterpriseteammode_status').innerHTML = CURR_ENTERPRISEMODE_STATUS;
            }else{
                document.getElementById('enterpriseteammode_landing').classList.add('hidden');
                document.getElementById('enterpriseteammode_status').classList.add('hidden');
            }
        }catch(exDiv){}
        //
        // we should setup the MOC & CE Broker fun (UI handles states for paid vs free)
        initMOCandCEBrokerWidgets();
        //
        if (CURR_STRIPESTATUS){
            setMOCandCEBrokerProfileUI();
            // for FourteenFish
            CheckAndDo_SmartTasksOnLoad();
        }
        // setup rewardful
        setRewardful();
        // we should fetch the credit balance
        fetchReflectionsAndCreditBalance((creditBalance)=>{
            document.querySelectorAll('.display-credits-earned').forEach((element)=>{
                element.innerHTML = creditBalance.totalUnclaimedCredts + " credit"+(creditBalance.totalUnclaimedCredts>1?'s':'');
                element.classList.remove('hidden');
            });
            //
            if (creditBalance.totalUnclaimedCredts > 0){
                document.querySelectorAll('.claim-credit-ui').forEach((element)=>{
                    element.classList.remove('hidden');
                })
            }else{
                document.querySelectorAll('.claim-credit-ui').forEach((element)=>{
                    element.classList.add('hidden');
                })
            }
            //
            // analytics call
            try{
                analytics.identify(CURR_USER.uid, {
                    totalCreditsEarnedAndClaimed: creditBalance.totalCredits,
                    currentUnclaimedCredits: creditBalance.totalUnclaimedCredts,
                    sampling_reflectiveContexts: (creditBalance.reflectiveContexts || []).slice(0, Math.min(3, (creditBalance.reflectiveContexts || []).length)),
                    sampling_reflectiveCategories: (creditBalance.reflectiveCategories || []).slice(0, Math.min(3, (creditBalance.reflectiveCategories || []).length)),
                });
            }catch(ex){
                Sentry.captureException(ex);
            }
            //
            displayCurrentReflections();
        });
        // we should show the certificates balance
        fetchCertificates((certificatePacket)=>{
            if (document.getElementById('claimed-certificates'))
                document.getElementById('claimed-certificates').innerHTML = '';
            // we should show the certificates
            if (certificatePacket?.certificates?.length === 0){
                if (document.getElementById('claimed-certificates'))
                    document.getElementById('claimed-certificates').innerHTML = '<div class="text-left text-gray-500 text-xs uppercase whitespace-nowrap py-4 pl-4 pr-3 sm:pl-0">No certificates claimed yet</div>';
            }
            //
            certificatePacket?.certificates?.forEach((certificate)=>{
                var certViewPacket = certificate;
                const certDateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute:'numeric' };
                certViewPacket.claimed_on_date = new Date(certificate.claimedOn).toLocaleString('en-US', certDateOptions);
                certViewPacket.creditsClaimed = certificate.creditCount + ".0";
                // MOC and CE Broker flags
                if (certificate.evaluation?.moc){
                    certViewPacket.show_moc = true;
                }else{certViewPacket.show_moc = false;}
                if (certificate.evaluation?.cebroker){
                    certViewPacket.show_cebroker = true;
                }else{certViewPacket.show_cebroker = false;}
                //
                document.getElementById('claimed-certificates').innerHTML += Mustache.render(document.getElementById('certificate-entry').innerHTML, certViewPacket);
            });
            //
            try{
                analytics.identify(CURR_USER.uid, {
                    totalCertsGenerated: certificatePacket?.certificates?.length || 0
                });
            }catch(ex){
                Sentry.captureException(ex);
            }
        });
        //
        mixpanel.track('Learner+ Hub Initialized', {CURR_USERPROFILE, CURR_STRIPESTATUS, CURR_STRIPEPACKET});
        var customerIoPacket = JSON.parse(JSON.stringify(CURR_USERPROFILE)); // graceful clone
        customerIoPacket.phoneNumber = CURR_USER.phoneNumber;
        customerIoPacket.stripe_status = CURR_STRIPESTATUS;
        customerIoPacket.enterpriseteam_mode = CURR_ENTERPRISEMODE_STATUS;
        if (CURR_STRIPEPACKET){
            customerIoPacket.stripe_subscription = CURR_STRIPEPACKET.subscription || "none";
            customerIoPacket.stripe_customer = CURR_STRIPEPACKET.customer || "no customer id";
            customerIoPacket.stripe_subscription_status = CURR_STRIPEPACKET.subscription_status || "no status";
        }
        customerIoPacket.rewardful_affiliate = CURR_USERPROFILE.rewardful_affiliate?.id || "no affiliate";
        analytics.identify(CURR_USER.uid, customerIoPacket);
        analytics.track('learnerplus_hub_initialized', {CURR_USERPROFILE, CURR_STRIPESTATUS, CURR_STRIPEPACKET});
        //
        if (CURR_QueryParams_Obj.navigate_to_subscribe){
            performTask('smart-manage-subscription');
        }
        //
        if (CURR_QueryParams_Obj.fromReminder){
            // SMS featureset https://learner.plus/?fromReminder=true
            performTask('start-default-reflection');
        }
    }

    function setPersonaSpecificUI(){
        console.log(CURR_USER.phoneNumber);
        // hide the regional views
        document.querySelectorAll('.persona-specific-view').forEach((element)=>{
            element.classList.add('hidden');
        });
        //
        var persona = "cme";
        //
        if (CURR_USERPROFILE.primarylocation === "United Kingdom"){
            persona = "cpd";   
        }
        document.querySelectorAll('.show-for-persona-'+persona).forEach((element)=>{
            element.classList.remove('hidden');
        });
        document.querySelectorAll('.remove-for-persona-'+persona).forEach((element)=>{
            element?.remove();
        });

        // 
        try{ 
            if (CURR_USERPROFILE.fourteenfish){
                document.getElementById('fourteenfish-connected-btnlabel').innerText = "FourteenFish Connected ✓";
            }
        }catch(ex){
            //noop
        }
    }

    function setProfileUI(){
        console.log(CURR_USERPROFILE);
        document.getElementById('first-name').value = CURR_USERPROFILE.firstname;
        document.getElementById('last-name').value = CURR_USERPROFILE.lastname;
        setSelectValue(CURR_USERPROFILE.credential, 'profile_credential');
        document.getElementById('profile_specialty').value = CURR_USERPROFILE.specialty||"";
        setSelectValue(CURR_USERPROFILE.primarylocation, 'profile_primarylocation');
        if (CURR_USERPROFILE.next_reflection_reminder){
            setSelectValue(CURR_USERPROFILE.primarylocation, 'profile_preferences-reminders');
        }
        //
        if (CURR_USERPROFILE.reflectce){
            document.getElementById('legacy_learnerhub')?.classList.remove('hidden');
        }
        // amplifi bros don't see this
        if (CURR_QueryParams_Obj.learnerhub){
            document.querySelectorAll('.unsubscribed-element').forEach((element)=>{
                element.classList.add("hidden");
            });

            document.querySelectorAll('.wrapped').forEach((element)=>{
                element.classList.add("hidden");
            });
            document.getElementById('moc_reporting_callout').classList.add('hidden');
            document.getElementById('moc_section').classList.add('hidden');

            document.getElementById('learnerplus-logo').innerHTML = `<span class="text-white font-bold text-md">Learner Hub</span>`;
        }
        
        // affiliate stuff @06-21-2024
        if (CURR_USERPROFILE.rewardful_affiliate){
            document.getElementById('my-affiliate-link').innerHTML = CURR_USERPROFILE.rewardful_affiliate?.links[0]?.url;
        }else{
            // Turning off rewardful affiliate creation @01-20-2025
            /* 
            Rewardful_CreateAffiliate((affiliatePacket)=>{
                if (affiliatePacket.error){
                    return; // no error to be notified here.
                    //return showNotification("Affiliate Creation Error", "There was an error creating your affiliate account - "+affiliatePacket.error, "error", 3000);
                }
                document.getElementById('my-affiliate-link').innerHTML = affiliatePacket?.links[0]?.url;
            });
            */
        }
    }

    function updateProfile(callback){
        CURR_USERPROFILE.firstname = document.getElementById('first-name').value;
        CURR_USERPROFILE.lastname = document.getElementById('last-name').value; 
        CURR_USERPROFILE.credential = document.getElementById('profile_credential').value;
        CURR_USERPROFILE.specialty = document.getElementById('profile_specialty').value;
        CURR_USERPROFILE.primarylocation = document.getElementById('profile_primarylocation').value;
        CURR_USERPROFILE.next_reflection_reminder = document.getElementById('profile_preferences-reminders').value;
        //
        if (CURR_USERPROFILE.next_reflection_reminder){
            if (CURR_USERPROFILE.next_reflection_reminder !== 'never'){
                var reminderPacket = getSmartRecurringReminderPacket(CURR_USER, CURR_USERPROFILE, CURR_USERPROFILE.next_reflection_reminder, 'Ready to reflect?');
                if (reminderPacket){
                    updateReminderSchedule(reminderPacket, (reminderUpdateError)=>{});
                }
            }
        }
        //
        return FB_UpdateProfile(callback);
    }

    function FB_UpdateProfile(callback){
        //
        firebase.firestore().collection('userprofiles').doc(CURR_USER.uid).set(CURR_USERPROFILE).then(()=>{
            showNotification('Profile Updated', 'Your profile has been updated.', 'success', 3000);
            return callback();
            //
        }).catch((error)=>{
            showNotification('Profile Update Error', 'There was an error updating your profile - '+error+'.', 'error', 3000);
            return callback({error});
        });
    }


    function setMOCandCEBrokerProfileUI(){
        console.log(CURR_USERPROFILE);
        try{
            // MOC properties
            if (CURR_USERPROFILE.moc){
                //
                var mocBoards = CURR_USERPROFILE.moc.moc_boards;
                for (var boardId in mocBoards){
                    if (mocBoards[boardId] === true && document.getElementById(`mocboard-${boardId}`)){
                        document.getElementById(`mocboard-${boardId}`).checked = true;
                    }else if (document.getElementById(`mocboard-${boardId}`)){
                        document.getElementById(`mocboard-${boardId}`).checked = false;
                    }
                }
                document.getElementById('moc-learnerid').value = CURR_USERPROFILE.moc.moc_learnerid;
                document.getElementById('moc-dob').value = CURR_USERPROFILE.moc.moc_dob;
                document.getElementById('moc-npi').value = CURR_USERPROFILE.npi.length === 10? CURR_USERPROFILE.npi:CURR_USERPROFILE.moc.moc_npi;
                //
                document.getElementById('moc_reporting_callout').classList.add('hidden');
                Array.from(document.getElementsByClassName('moc_enabled_flag')).forEach((element) => {
                    element.classList.remove('hidden');
                });
                //
    
            }
            // CE Broker properties
            if (CURR_USERPROFILE.cebroker){
                document.getElementById('cebroker-license').value = CURR_USERPROFILE.cebroker.license;
                //
                document.getElementById('syncStatus_CEB').classList.remove('hidden');
                //
            }
        }catch(ex){
            // we might be in unsubscribed mode
            console.log("We might be in unsubscribed mode, so this function is not appropriate");
            console.log(ex);
        }
    }

    function updateMOCToUserProfile(callbackTrueOfFalse){
        var boardHash = {};
        if (document.getElementById('moc-learnerid').value.length < 3){
            return callbackTrueOfFalse(false);
        }
        if (document.getElementById('moc-dob').value.length < 5){
            return callbackTrueOfFalse(false);
        }
        if (document.getElementById('moc-npi').value.length < 5){
            return callbackTrueOfFalse(false);
        }
        var atleastOneBoardSelected = false;
        Array.from(document.getElementsByClassName('mocboard-checkbox')).forEach((element) => {
            const board = element.getAttribute('data-mocboard'); // Access the 'data-board' attribute
            if (element.checked) {
                boardHash[board] = true;
                atleastOneBoardSelected = true;
            } else {
                boardHash[board] = false;
            }
        });
        if (!atleastOneBoardSelected){
            return callbackTrueOfFalse(false);
        }
        // we are good to go, let's update the MOC packet (include the other required fields too)
        CURR_USERPROFILE.moc = {
            moc_firstname: CURR_USERPROFILE.firstname,
            moc_lastname: CURR_USERPROFILE.lastname,
            moc_learnerid: document.getElementById('moc-learnerid').value,
            moc_dob: document.getElementById('moc-dob').value,
            moc_npi: document.getElementById('moc-npi').value,
            moc_boards: boardHash
        };
        //
        console.log("MOC Profile Updates", CURR_USERPROFILE);
        return callbackTrueOfFalse(true);
    }

    function updateCEBToUserProfile(callbackTrueOfFalse){
        if (document.getElementById('cebroker-license').value.length > 5){
            if (!getCEBrokerLicenseeCode(document.getElementById('cebroker-license').value)){
                return callbackTrueOfFalse(false);
            }
            var usStateChecker = (CURR_USERPROFILE.primarylocation||"").split("-");
            if (usStateChecker.length !== 2){
                // we don't have a valid state
                return callbackTrueOfFalse(false);
            }
            CURR_USERPROFILE.cebroker = {
                licensee_profession: getCEBrokerLicenseeCode(document.getElementById('cebroker-license').value).licenseeCode,
                license: document.getElementById('cebroker-license').value,
                cebroker_state: usStateChecker[1], //IL
                first_name: CURR_USERPROFILE.firstname,
                last_name: CURR_USERPROFILE.lastname,
            }
        }else{
            return callbackTrueOfFalse(false);
        }
        //
        console.log("CEBroker Profile Updates", CURR_USERPROFILE);
        return callbackTrueOfFalse(true);
    }
    
    function getCEBrokerLicenseeCode(license){
        const regex = /^([A-Za-z]+)\.?(\d+)$/; // Letters followed by optional period and numbers
        const match = license.match(regex);

        if (match) {
            const licenseeCode = match[1]; // Extracts the letters (e.g., 'DN', 'SP')
            return { licenseeCode };
        } else {
            console.error('Invalid license format:', license);
            return null;
        }
    }
    
    function deletePreviousReminder(reminderPacket, callback){
        console.log(reminderPacket);
        firebase.firestore().collection('reminders').where('owner_uid', '==', reminderPacket.owner_uid).get().then((querySnapshot)=>{
            querySnapshot.forEach((doc)=>{
                doc.ref.delete();
            });
            callback()
        }).catch((error)=>{
            Sentry.captureException(error);
            callback();            
        });
    }

    function updateReminderSchedule(reminderPacket, callback){
        console.log('updating reminder schedule', reminderPacket);
        // we must remove the old reminder if it exists
        deletePreviousReminder(reminderPacket, function(){
            firebase.firestore().collection('reminders').doc(`r${reminderPacket.next_reminder_epoch}`).set(reminderPacket).then(()=>{
                console.log('reminder saved');
                return callback();
                //
            }).catch((error)=>{
                console.error('Error saving reminder: ', error);
                return callback({error});
            });    
        });
    }
    
    
    // uses the current reflections (unclaimed credits) to display in Journal
    function displayCurrentReflections(){
        document.getElementById('current-reflections').innerHTML = "";
        //reflection-journal-entry-reflective-practice or reflection-journal-entry-reflective-credit-marquette
        var reflectionTemplate = document.getElementById('reflection-journal-entry-'+CURR_REFLECTIONKIT).innerHTML;
        for (var i=0; i<CURR_LEARNERSTATE.reflections.length; i++){
            var refViewPacket = CURR_LEARNERSTATE.reflections[i];
            refViewPacket.reflected_on_date = new Date(refViewPacket.reflectedOn).toDateString();
            document.getElementById('current-reflections').innerHTML += Mustache.render(reflectionTemplate, refViewPacket);
        }
    }

    function RefineViewForReflectionKit(){
        switch (CURR_REFLECTIONKIT){
            case "reflective-credit-marquette":
                document.querySelector('#lrnrpls_journal').classList.remove('hidden');
                var buttonToReflect = document.createElement('a');
                buttonToReflect.innerHTML = "Reflect Again<i class='fa-solid fa-chevron-right ml-2 text-white'></i>";
                buttonToReflect.classList.add('inline-block', 'cursor-pointer', 'mb-8', 'text-lg', 'px-4', 'py-2', 'text-white', 'bg-learner-dark-blue', 'rounded', 'shadow-md', 'hover:bg-learner-dark-blue/70', 'hover:shadow-lg');
                buttonToReflect.onclick = function(){
                    window.location.href = '/'+CURR_REFLECTIONKIT;
                }
                document.querySelector('#lrnrpls_journal').insertBefore(buttonToReflect, document.querySelector('#lrnrpls_journal').firstChild);
                document.querySelectorAll('.hide-for-kit-reflective-credit-marquette').forEach((element)=>{
                    element.remove();
                });
                break;
            default:
                // reflective-practice - do no thing :) 
                break;
        }
    }

    // fetches the reflections and credit balance
    function fetchReflectionsAndCreditBalance(callback){
        firebase.firestore().collection('reflections').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).get() // Use the count aggregation query
            .then((docSnapshot) => {
                var totalUnclaimedCredts = 0;
                var totalCredits = 0;
                var reflectiveContexts = [];
                var reflectiveCategories = [];
                docSnapshot.docs.forEach((doc)=>{
                    totalCredits += doc.data().credit;
                    reflectiveContexts.push(doc.data().ipoc_context);
                    reflectiveCategories.push(doc.data().reflection_based_reflective_category || "General Reflection");
                    if (!doc.data().claimedOn){
                        totalUnclaimedCredts += doc.data().credit;
                        CURR_LEARNERSTATE.reflections.push(doc.data());
                        CURR_LEARNERSTATE.reflection_ids.push(doc.id);
                    }
                });
                //
                CURR_LEARNERSTATE.creditBalance = totalUnclaimedCredts;
                CURR_LEARNERSTATE.reflections = CURR_LEARNERSTATE.reflections.sort((a, b) => (a.reflectedOn > b.reflectedOn) ? -1 : 1);
                //
                reflectiveContexts = [...new Set(reflectiveContexts)];
                reflectiveCategories = [...new Set(reflectiveCategories)];
                //
                return callback({totalUnclaimedCredts, totalCredits, reflectiveContexts, reflectiveCategories});
            })
            .catch((error) => {
              console.error('Error getting document count: ', error);
              return callback({error});
            });
    }

    // fetches certificates
    function fetchCertificates(callback){
        firebase.firestore().collection('certificates').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).get()
            .then((docSnapshot) => {
                CURR_LEARNERSTATE.certificates = [];
                docSnapshot.docs.forEach((doc)=>{
                    var cert = doc.data();
                    cert.certificateId = doc.id;
                    CURR_LEARNERSTATE.certificates.push(cert);
                });
                //
                CURR_LEARNERSTATE.certificates.sort((a,b)=>{
                    return b.claimedOn-a.claimedOn;
                })
                //
                return callback({certificates: CURR_LEARNERSTATE.certificates});
            })
            .catch((error) => {
              console.error('Error getting document count: ', error);
              return callback({error});
            });
    }

    // Evaluation & Certificate
    var tfPopup = null;
    function GenerateAndDownloadCertificate(certificateId){
        firebase.firestore().collection('certificates').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).doc(certificateId).get().then((doc)=>{
            if (doc.exists){
                mixpanel.track('Downloading certificate', {CURR_USERPROFILE, certificateId});
                analytics.track('learnerplus_certificate_download', {CURR_USERPROFILE, certificateId});
                showNotification("Downloading certificate...", "Check your downloads folder or ensure that your browser is not blocking downloads","",7200)
                var creditGenPacket = {
                    learner_name: CURR_USERPROFILE.firstname + " " + CURR_USERPROFILE.lastname,
                    credits_claimed: doc.data().creditCount,
                    credits_claimedon: (new Date(doc.data().claimedOn)).getTime(),
                    credential: CURR_USERPROFILE.credential,
                    specialty: CURR_USERPROFILE.specialty||""
                }
                //
                var a = document.createElement('A');
                a.href=`/generate-certificate?learner_name=${creditGenPacket.learner_name}&credits_claimed=${creditGenPacket.credits_claimed}&credits_claimedon=${creditGenPacket.credits_claimedon}&credential=${creditGenPacket.credential}&specialty=${creditGenPacket.specialty}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                // initiate app again :) 
                InitiateMainApp();
                //
            }else{
                showNotification("Certificate not found","","",5400);
            }
        }).catch((error)=>{
            showNotification("Certificate fetch error", error, "",5400);
        });
        //
    }
    function GenerateAndDownloadMOCCertificate(certificateId){
        firebase.firestore().collection('certificates').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).doc(certificateId).get().then((doc)=>{
            if (doc.exists){
                mixpanel.track('Downloading MOC certificate', {CURR_USERPROFILE, certificateId});
                analytics.track('learnerplus_certificate_download', {CURR_USERPROFILE, certificateId});
                showNotification("Downloading MOC certificate...", "Check your downloads folder or ensure that your browser is not blocking downloads","",7200)
                var creditGenPacket = {
                    learner_name: CURR_USERPROFILE.firstname + " " + CURR_USERPROFILE.lastname,
                    credits_claimed: doc.data().creditCount,
                    credits_claimedon: (new Date(doc.data().claimedOn)).getTime(),
                    credential: CURR_USERPROFILE.credential,
                    specialty: CURR_USERPROFILE.specialty||""
                }
                // 
                var mocBoard = "abim";
                //
                if (CURR_USERPROFILE.moc){
                    for (var board in CURR_USERPROFILE.moc.moc_boards){
                        if (CURR_USERPROFILE.moc.moc_boards[board]){
                            mocBoard = board;
                        }
                    }
                }
                var a = document.createElement('A');
                a.href=`/generate-certificate?learner_name=${creditGenPacket.learner_name}&credits_claimed=${creditGenPacket.credits_claimed}&credits_claimedon=${creditGenPacket.credits_claimedon}&credential=MOC-${mocBoard}&specialty=${creditGenPacket.specialty}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                //
            }else{
                showNotification("Certificate not found","","",5400);
            }
        }).catch((error)=>{
            showNotification("Certificate fetch error", error, "",5400);
        });
    }

    function GetCertificateResponseIdForMatching(certificateId, callback){
        firebase.firestore().collection('certificates').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).doc(certificateId).get().then((doc)=>{
            if (doc.exists){
                // let's get the certificate response id
                var certificateResponseId = doc.data().evaluation.response_id;
                // 
                return callback(certificateResponseId);
            }else{
                showNotification("Invalid certificate - transcript not found","","",5400);
                return callback(false);
            }
        }).catch((error)=>{
            showNotification("Certificate fetch error", error, "",5400);
            return callback(false);
        });
    }

    function LaunchLearnerPlusTranscript(certificateId){
        console.log(certificateId);
        firebase.firestore().collection('certificates').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).doc(certificateId).get().then((doc)=>{
            if (doc.exists){
                // let's get the certificate response id
                var certificateResponseId = doc.data().evaluation.response_id;
                // 
                if (CURR_STRIPESTATUS){
                    window.location.href = `/report-reflectivelearningtranscript?cert_response_id=${certificateResponseId}`;
                }else{
                    showValidationModal('⚠️ Only available to subscribers ⚠️', `Learner+ transcripts showcase your learning impacts and reflective credit-earning journey. Plus, if you plan on submitting your learning expenses for reimbursement, you'll want to include your Learner+ transcripts!<br/><a href="${LearnerPlusTranscriptFeatureImagePath}" target="_blank"><img src="${LearnerPlusTranscriptFeatureImagePath}" class="w-full h-auto rounded-lg shadow-lg hover:border hover:border-blue-500 hover:border-4"></a><br/>Click below to unlock unlimited reflections &rArr; credits &rArr; certificates &amp; transcripts for $50/yr only.`, `Subscribe now`,
                    function(){
                        mixpanel.track('Click to Subscribe for Transcripts', {CURR_STRIPEPACKET});
                        analytics.track('learnerplus_click_to_subscribe_for_transcripts', {CURR_STRIPEPACKET});
                        performTask("start-subscription");
                    }, 
                    'Not now', 
                    null);
                }
            }else{
                showNotification("Invalid certificate - transcript not found","","",5400);
            }
        }).catch((error)=>{
            showNotification("Certificate fetch error", error, "",5400);
        });
    }
    // https://colearning.typeform.com/to/HotrLCrF#learner_uid=xxxxx&learner_email=xxxxx&learner_credit_balance=xxxxx
    // https://colearning.typeform.com/to/HotrLCrF#learner_uid=xxxxx&learner_email=xxxxx&learner_credit_balance=xxxxx&learner_first=xxxxx&learner_last=xxxxx&moc_dob_mmdd=xxxxx&moc_board=xxxxx&moc_boardid=xxxxx&moc_npi=xxxxx
    function LaunchPrecertEvaluation(callback){
        if (CURR_LEARNERSTATE.creditBalance < 1){
            showNotification('No credits to claim', 'You do not have enough credits to claim and generate a certificate!', 'error', 3600);
            return callback(false);
        }
        //
        var hiddenPacket_Cert = {
            learner_uid: CURR_USER.uid,
            learner_email: CURR_USERPROFILE.email,
            learner_credential: CURR_USERPROFILE.credential || "Lifelong Learner",
            learner_credit_balance: CURR_LEARNERSTATE.creditBalance + ".0",
            learner_first: CURR_USERPROFILE.firstname || "Learner First Name",
            learner_last: CURR_USERPROFILE.lastname || "Learner Last Name",
            moc_dob_mmdd: "no-moc",
            moc_board: "no-moc",
            moc_boardid: "no-moc",
            moc_npi: "no-moc"
        };
        // 
        try{
            if (CURR_USERPROFILE.moc && CURR_STRIPESTATUS === true){
                //
                var moc_board = false;
                for (var a in CURR_USERPROFILE.moc.moc_boards){
                    if (CURR_USERPROFILE.moc.moc_boards[a]){
                        moc_board = a;
                    }
                }
                //
                if (moc_board){
                    hiddenPacket_Cert.moc_dob_mmdd = CURR_USERPROFILE.moc.moc_dob;
                    hiddenPacket_Cert.moc_board = moc_board;
                    hiddenPacket_Cert.moc_boardid = CURR_USERPROFILE.moc.moc_learnerid;
                    hiddenPacket_Cert.moc_npi = CURR_USERPROFILE.moc.moc_npi;    
                }
            }
        }catch(ex){
            // ignore 
        }
        //
        tfPopup = createPopup('HotrLCrF',{
            hidden: hiddenPacket_Cert,
            onSubmit: function(submitPayload){
                console.log('form submitted, download certificate', submitPayload);
                callback(submitPayload);
            },
            onClose: function(){
                console.log('form closed - certificate not downloaded');
                callback(false);
            }
        });
        tfPopup.open();
        mixpanel.track('Certificate Evaluation Started', {CURR_USERPROFILE});
        analytics.track('learnerplus_certificate_evaluation_started', {CURR_USERPROFILE});
    }

    function LaunchPrecertEvaluationFlow(){
        LaunchPrecertEvaluation((submitPayload)=>{
            if (submitPayload){
                tfPopup.close();
                ConvertCreditsToCertificate(submitPayload, (claimStatusAndCertificateId)=>{
                    // download 
                    console.log(claimStatusAndCertificateId);
                    mixpanel.track('Certificate Evaluation Completed', {CURR_USERPROFILE, claimStatusAndCertificateId});
                    analytics.track('learnerplus_certificate_evaluation_completed', {CURR_USERPROFILE, claimStatusAndCertificateId});
                    //
                    if (claimStatusAndCertificateId.status === "success"){
                        GenerateAndDownloadCertificate(claimStatusAndCertificateId.certificateId);
                        // we should perform the credit claimer
                        fetchReflectionsAndCreditBalance((creditBalance)=>{
                            document.querySelectorAll('.display-credits-earned').forEach((element)=>{
                                element.innerHTML = creditBalance.totalUnclaimedCredts + " credit"+(creditBalance.totalUnclaimedCredts>1?'s':'');
                                element.classList.remove('hidden');
                            });
                            //
                            if (creditBalance.totalUnclaimedCredts > 0){
                                document.querySelectorAll('.claim-credit-ui').forEach((element)=>{
                                    element.classList.remove('hidden');
                                })
                            }else{
                                document.querySelectorAll('.claim-credit-ui').forEach((element)=>{
                                    element.classList.add('hidden');
                                })
                            }
                            //
                            // analytics call
                            try{
                                analytics.identify(CURR_USER.uid, {
                                    totalCreditsEarnedAndClaimed: creditBalance.totalCredits,
                                    currentUnclaimedCredits: creditBalance.totalUnclaimedCredts,
                                    sampling_reflectiveContexts: (creditBalance.reflectiveContexts || []).slice(0, Math.min(3, (creditBalance.reflectiveContexts || []).length)),
                                    sampling_reflectiveCategories: (creditBalance.reflectiveCategories || []).slice(0, Math.min(3, (creditBalance.reflectiveCategories || []).length)),
                                });
                            }catch(ex){
                                Sentry.captureException(ex);
                            }
                            //
                            displayCurrentReflections();
                        });
                        
                    }else{
                        showNotification("Error in certificate generation","","",3600);
                    }
                });
            }
        });
    }

    // This function converts credits to certificates based on validation
    function ConvertCreditsToCertificate(tfCertPacket, callback){
        // we check if this user has MOC turned on and CEBroker, AND they are a paying subscriber; and we add it here 
        if (CURR_USERPROFILE.moc && CURR_STRIPESTATUS === true){
            tfCertPacket.moc = CURR_USERPROFILE.moc;
        }
        if (CURR_USERPROFILE.cebroker && CURR_STRIPESTATUS === true){
            tfCertPacket.cebroker = CURR_USERPROFILE.cebroker;
        }
        // finalize-claim
        var payloadForClaim = {
            uid: CURR_USER.uid,
            reflectionkit: CURR_REFLECTIONKIT,
            certEvalPacket: tfCertPacket,
            reflection_ids: CURR_LEARNERSTATE.reflection_ids    
        }
        //
        axios.post(`/finalize-claim`, payloadForClaim)
            .then((response) => {
                console.log(response);
                return callback(response.data);
            })
            .catch((error) => {
                console.error('Error finalizing claim: ', error);
                return callback({error});
            });
    }

    function Rewardful_CreateAffiliate(callback){
        axios.get(`/rewardful/create-affiliate?uid=${CURR_USER.uid}&firstname=${CURR_USERPROFILE.firstname}&lastname=${CURR_USERPROFILE.lastname}&email=${CURR_USERPROFILE.email}`)
            .then((response) => {
                console.log(response);
                return callback(response.data);
            })
            .catch((error) => {
                //console.error('Error creating affiliate: ', error);
                return callback({error});
            });
    }

    function LoadReflectivePayload(certificateId, callback){
        GetCertificateResponseIdForMatching(certificateId, (certificateResponseId)=>{
            firebase.firestore().collection('reflections').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).where("certificate.response_id", "==", certificateResponseId).get().then((docSnapshot)=>{
                var reflectionLines = [];
                if (docSnapshot.docs.length > 0){
                    for (var i=0; i<docSnapshot.docs.length; i++){
                        var reflectionObj = docSnapshot.docs[i].data();
                        reflectionLines.push(`Topic: ${reflectionObj.ipoc_keyword}; 
 Context ${reflectionObj.ipoc_context}; 
 Initial Reflection- ${reflectionObj.reflection_initial}}; 
 Deeper Reflection- ${reflectionObj.reflection_deeper}; 
 Learning & Pathways: ${reflectionObj.validation_deeper.learning_pathways.join('| ')}`);
                    }
                    return callback(reflectionLines.join(" \n"));
                }else{
                    callback(false);
                }
            });    
        });
    }

    function LoadReflectivePracticeTranscript_StringPayload(certificateId, callback){
        // we load the transcript
        console.log("Load transcript for", CURR_USER.uid, CURR_REFLECTIONKIT, certificateId);
        GetCertificateResponseIdForMatching(certificateId, (certificateResponseId)=>{
            if (!certificateResponseId){
                return callback(false);
            }else{
                var totalReflections = 0;
                var clinical_keywords = [];
                var impacts = [];
                var ipocTranscriptTableArr = [];
                //
                var ce_transcript_sections = [];
                //
                var personalized_learningobjectives = [];
                var potential_barriers = [];
                var suggested_pathways = [];
                //
                firebase.firestore().collection('reflections').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).where("certificate.response_id", "==", certificateResponseId).get() // Use the count aggregation query
                    .then((docSnapshot) => {
                        docSnapshot.docs.forEach((doc)=>{
                            console.log(doc.data());                    
                            var reflectionData = doc.data();
                            //
                            ce_transcript_sections.push(`My reflections on the topic of "${reflectionData.ipoc_keyword}" identified and signaled these learning objectives: ${reflectionData.ce_assessment.objectives.join(', ')} and these impacts: ${[...new Set(reflectionData.validation_initial.impact_detection.concat(reflectionData.validation_deeper.impact_detection))].join(', ')}. My keywords were: ${reflectionData.validation_initial.extracted_keywords.join(', ')}. \n`);
                            //
                            ipocTranscriptTableArr.push({
                                ipoc_keyword: reflectionData.ipoc_keyword,
                                ipoc_context: reflectionData.ipoc_context,
                                reflected_on: reflectionData.reflectedOn,
                                reflected_on_date: new Date(reflectionData.reflectedOn).toDateString(),
                                impacts: [...new Set(reflectionData.validation_initial.impact_detection.concat(reflectionData.validation_deeper.impact_detection))].map(x=>('✓ '+x.toLowerCase())).join("<br/>"),
                                barriers: [...new Set(reflectionData.validation_initial.detected_barriers.concat(reflectionData.validation_deeper.detected_barriers))].map(x=>('⚠️ '+x.toLowerCase())).join("<br/>"),
                            });
                            //
                            totalReflections++;
                            clinical_keywords = clinical_keywords.concat(reflectionData.validation_initial.extracted_keywords.map(x=>x.toLowerCase()));
                            clinical_keywords = clinical_keywords.concat(reflectionData.validation_deeper.extracted_keywords.map(x=>x.toLowerCase()));
                            //
                            impacts = impacts.concat(reflectionData.validation_initial.impact_detection.map(x=>x.toLowerCase()));
                            impacts = impacts.concat(reflectionData.validation_deeper.impact_detection.map(x=>x.toLowerCase()));
                            //
                            if ((reflectionData.ce_assessment.confidence === "high")
                                || (reflectionData.ce_assessment.significance === "high" || reflectionData.ce_assessment.significance === "medium")){
                                personalized_learningobjectives.push(...reflectionData.ce_assessment.objectives);
                                potential_barriers.push(...new Set(reflectionData.validation_initial.detected_barriers.concat(reflectionData.validation_deeper.detected_barriers)));
                                suggested_pathways.push(reflectionData.ce_assessment.strategies);
                
                            }
                        });
                        // display
                        personalized_learningobjectives = [...new Set(personalized_learningobjectives)];
                        potential_barriers = [...new Set(potential_barriers)];
                        suggested_pathways = [...new Set(suggested_pathways)];
                        if (potential_barriers.length > 0){
                            ce_transcript_sections.push(`\nI also havee identified potential barriers to my learning and practice: ${potential_barriers.join(', ')}.\n`);
                        }
                        //
                        console.log("Transcript Payload", ce_transcript_sections);
                        callback(ce_transcript_sections.join("\n"));
                        //
                    })
                    .catch((error) => {
                    console.error('Error getting reflections', error);
                    return callback(false);
                    });
            }
        });
    }

    function GenerateOrShowNarrative(certificateId){
        if (CURR_STRIPESTATUS){
            // we should check if this user has a narrative already
            firebase.firestore().collection('narratives').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).doc(certificateId).get().then((doc)=>{
                if (doc.exists){
                    // show the narrative
                    var learningNarrative = doc.data().learning_narrative;
                    return showValidationModal('Your CPD Insights', `Leverage this summary during CME/CPD reviews, appraisals and to enhance your reflective learning journey.<br><br><div id="learning-narrative">${learningNarrative}</div>`, 'Copy to Clipboard', ()=>{
                        navigator.clipboard.writeText(document.getElementById('learning-narrative').innerText).then(() => {
                            showNotification("Reflective Insights copied", "Your insights have been copied to your clipboard.", "success", 3600);
                            performTask('validationmodal-dismiss');
                        }).catch(err => {
                            console.error('Could not copy text: ', err);
                        });
                    }, 'Close', ()=>{performTask('validationmodal-dismiss');});
                }else{
                    // we should generate the narrative and save it
                    LoadReflectivePracticeTranscript_StringPayload(certificateId, (ce_transcript_sections)=>{
                        if (!ce_transcript_sections){
                            return showNotification("Narrative generation error", "There was an error generating your narrative. Please try again later!", "",5400);
                        }
                        // 
                        var payloadForNarrative = {
                            ce_transcript: ce_transcript_sections
                        };
                        //
                        showLoadingOverlay("Generating your learning narrative...");
                        axios.post(`/reflective-practice-generate-narrative`, payloadForNarrative)
                            .then((response) => {
                                console.log(response.data);
                                hideLoadingOverlay();
                                // save the narrative here and present it;
                                console.log("save the narrative and show it");
                                //
                                firebase.firestore().collection('narratives').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).doc(certificateId).set({learning_narrative: response.data.validation.learning_narrative}).then(()=>{
                                    var learningNarrative = response.data.validation.learning_narrative;
                                    return showValidationModal('Your CPD Insights', `Leverage this summary during CME/CPD reviews, appraisals and to enhance your reflective learning journey.<br><br><div id="learning-narrative">${learningNarrative}</div>`, 'Copy to Clipboard', ()=>{
                                        navigator.clipboard.writeText(document.getElementById('learning-narrative').innerText).then(() => {
                                            showNotification("Reflective Insights copied", "Your insights have been copied to your clipboard.", "success", 3600);
                                            performTask('validationmodal-dismiss');
                                        }).catch(err => {
                                            console.error('Could not copy text: ', err);
                                        });
                                    }, 'Close', ()=>{performTask('validationmodal-dismiss');});
                                    //return showValidationModal('Your CPD Insights', `Leverage this insight during CME/CPD reviews, appraisals and to enhance your reflective learning journey.<br>${response.data.validation.learning_narrative}`, 'Close', ()=>{ performTask('validationmodal-dismiss');}, '', null);
                                }).catch((error)=>{
                                    console.error('Error saving narrative: ', error);
                                    showNotification("Narrative generation error. Please try again later!", error, "",5400);
                                });;
                            })
                            .catch((error) => {
                                console.error('Error generating narrative ', error);
                                showNotification("Narrative generation error. Please try again later!", error, "",5400);
                                hideLoadingOverlay();
                            });
                    });

                }
            }).catch((error)=>{
                showNotification("Narrative fetch error", error, "",5400);
            });
        }else{
            showValidationModal('⚠️ Only available to subscribers ⚠️', `Learner+ can generate CPD narratives and to quickly prep for your appraisals, reviews or quickly summarizing your learning impact!<br/>Click below to unlock unlimited reflections &rArr; credits &rArr; certificates &amp; transcripts, and more for $50/yr only.`, `Subscribe now`,
            function(){
                mixpanel.track('Click to Subscribe for Narratives', {CURR_STRIPEPACKET});
                analytics.track('learnerplus_click_to_subscribe_for_narratives', {CURR_STRIPEPACKET});
                performTask("start-subscription");
            }, 
            'Not now', 
            null);
        }
    }

    /* Agents */
    var CURR_CPDCopilot_CertID = false;
    var CURR_CPDCopilot_TaskHashMap = {};
    function ShowCPDAgents(certificateId){
        CURR_CPDCopilot_CertID = certificateId;
        var credential = CURR_USERPROFILE.credential || "Lifelong Learner";
        axios.post(`/agent/copilot/get-available-tasks`, {credential}).then((response)=>{
            var cpd_agent_tasks = response.data;
            cpd_agent_tasks = cpd_agent_tasks.sort((a,b)=>{
                if (a.task_category === "prepare"){
                    return -1;
                }
                if (b.task_category === "prepare"){
                    return 1;
                }
                if (a.task_category === "compile"){
                    return -1;
                }
                if (b.task_category === "compile"){
                    return 1;
                }
                return 0;
            });
            
            //
            var copilotTaskDevHtml = ``;
            cpd_agent_tasks.map((task)=>{
                CURR_CPDCopilot_TaskHashMap[task.task_id] = task;
                task.CURR_CPDCopilot_CertID = CURR_CPDCopilot_CertID;
                // for prepare
                task.primary_color = "learner-blue";
                task.fontawesome_icon = "fa-sharp-duotone fa-regular fa-diagram-venn";
                if (task.task_category === "compile"){
                    task.fontawesome_icon = "fa-sharp-duotone fa-regular fa-notebook";
                    task.primary_color = "teal-500";
                }else if (task.task_category === "analyze"){
                    task.fontawesome_icon = "fa-sharp-duotone fa-regular fa-magnifying-glass-chart";
                    task.primary_color = "pink-500";
                }
                //
                copilotTaskDevHtml += Mustache.render(document.getElementById('copilot-agent-task').innerHTML, task);
            })
            
            //
            // <span class="text-xs">Your CPD Copilot is ready to assist you with your CPD requirements. Select a task below:<br><br>${cpd_agent_tasks.map(x=>`<a data-task="perform-cpd-agent-task::${CURR_CPDCopilot_CertID}~${x.task_id}" class="ui-action-btn cursor-pointer font-semibold text-blue-500 hover:underline">${x.task_name} &rarr;</a><br/><span class="text-xs" style="font-size:0.8em;">${x.description}</span>
            showValidationModal('Copilot: Do more with your reflections', `<ul role="list" class="mt-1 grid grid-cols-1 gap-2">${copilotTaskDevHtml}</ul>`, 'Close', ()=>{performTask('validationmodal-dismiss');}, '', null);
        }).catch((error)=>{});
    }

    function GenerateOrShowCPDCopilotNarrative(certificateId, task_id){
        performTask('validationmodal-dismiss');
        if (CURR_STRIPESTATUS){
            // we should check if this user has a narrative already
            firebase.firestore().collection('narratives').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).doc(task_id + "--" + certificateId).get().then((doc)=>{
                if (doc.exists){
                    // show the narrative
                    var cpdNarrative = doc.data().copilot_output;
                    var cpdTask = doc.data().task_name;
                    return showValidationModal('Your CPD Copilot: ' + cpdTask, `<div id="learning-narrative">${convertMarkdownToHTML(cpdNarrative)}</div>`, 'Copy to Clipboard', ()=>{
                        navigator.clipboard.writeText(document.getElementById('learning-narrative').innerText).then(() => {
                            showNotification("CPD Copilot Insights copied", "Your insights have been copied to your clipboard.", "success", 3600);
                            performTask('validationmodal-dismiss');
                        }).catch(err => {
                            console.error('Could not copy text: ', err);
                        });
                    }, 'Close', ()=>{performTask('validationmodal-dismiss');});
                }else{
                    // we should generate the narrative and save it
                    LoadReflectivePayload(certificateId, (reflectivePayload)=>{
                        if (!reflectivePayload){
                            return showNotification("CPD Insight generation error", "There was an error generating your narrative. Please try again later!", "",5400);
                        }
                        // 
                        var payloadForCPDCopilotAgentTask = {
                            task_id: task_id,
                            reflection_payload: reflectivePayload
                        };
                        //
                        var firstCharLowercase_taskname = CURR_CPDCopilot_TaskHashMap[task_id].description.charAt(0).toLowerCase() + CURR_CPDCopilot_TaskHashMap[task_id].description.slice(1);
                        showLoadingOverlay("Learner+ is analyzing your reflections to " + firstCharLowercase_taskname);
                        axios.post(`/agent/copilot/perform-task`, payloadForCPDCopilotAgentTask)
                            .then((response) => {
                                hideLoadingOverlay();
                                // save the narrative here and present it;
                                console.log("save the narrative and show it");
                                //
                                firebase.firestore().collection('narratives').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).doc(task_id+"--"+certificateId).set({copilot_output: response.data?.validation?.copilot_output, task_name: response.data?.validation?.task_name || "CPD Smart Insight"}).then(()=>{
                                    var cpdNarrative = response.data.validation.copilot_output;
                                    var cpdTask = response.data.validation.task_name || "CPD Smart Insight";
                                    return showValidationModal('Your CPD Copilot: ' + cpdTask, `<div id="learning-narrative">${convertMarkdownToHTML(cpdNarrative)}</div>`, 'Copy to Clipboard', ()=>{
                                        navigator.clipboard.writeText(document.getElementById('learning-narrative').innerText).then(() => {
                                            showNotification("CPD Copilot Insights copied", "Your insights have been copied to your clipboard.", "success", 3600);
                                            performTask('validationmodal-dismiss');
                                        }).catch(err => {
                                            console.error('Could not copy text: ', err);
                                        });
                                    }, 'Close', ()=>{performTask('validationmodal-dismiss');});
                                }).catch((error)=>{
                                    console.error('Error saving narrative: ', error);
                                    showNotification("Narrative generation error. Please try again later!", error, "",5400);
                                });;
                            })
                            .catch((error) => {
                                console.error('Error generating narrative ', error);
                                showNotification("Narrative generation error. Please try again later!", error, "",5400);
                                hideLoadingOverlay();
                            });
                    });

                }
            }).catch((error)=>{
                showNotification("Narrative fetch error", error, "",5400);
            });
        }else{
            showValidationModal('⚠️ Only available to subscribers ⚠️', `Learner+ can generate CPD narratives and to quickly prep for your appraisals, reviews or quickly summarizing your learning impact!<br/>Click below to unlock unlimited reflections &rArr; credits &rArr; certificates &amp; transcripts, and more for $50/yr only.`, `Subscribe now`,
            function(){
                mixpanel.track('Click to Subscribe for Narratives', {CURR_STRIPEPACKET});
                analytics.track('learnerplus_click_to_subscribe_for_narratives', {CURR_STRIPEPACKET});
                performTask("start-subscription");
            }, 
            'Not now', 
            null);
        }
    }

    // recap can be shown for free, but maybe the credit and summaries might be for the public
    function GenerateOrShowRecap(startEpoch, endEpoch){
        if (true){//CURR_STRIPESTATUS || 
            firebase.firestore().collection('reflections').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).where("reflectedOn", ">=", startEpoch).where("reflectedOn", "<=", endEpoch).get().then((docSnapshot)=>{
                var storylines = {
                    totalCredits: 0,
                    reflectiveContexts: [],
                    clinicalKeywords: [],
                    impacts: [],
                    barriers: [],
                    suggestedPathways: []
                };
                if (docSnapshot.docs.length > 0){
                    for (var i=0; i<docSnapshot.docs.length; i++){
                        var reflectionObj = docSnapshot.docs[i].data();
                        storylines.totalCredits += reflectionObj.credit;
                        storylines.reflectiveContexts.push(reflectionObj.ipoc_context);
                        storylines.clinicalKeywords = storylines.clinicalKeywords.concat(reflectionObj.validation_initial.extracted_keywords);
                        storylines.clinicalKeywords = storylines.clinicalKeywords.concat(reflectionObj.validation_deeper.extracted_keywords);
                        storylines.impacts = storylines.impacts.concat(reflectionObj.validation_initial.impact_detection);
                        storylines.impacts = storylines.impacts.concat(reflectionObj.validation_deeper.impact_detection);
                        storylines.barriers = storylines.barriers.concat(reflectionObj.validation_initial.detected_barriers);
                        storylines.barriers = storylines.barriers.concat(reflectionObj.validation_deeper.detected_barriers);
                        storylines.suggestedPathways.push(reflectionObj.ce_assessment.strategies);
                    }
                    storylines.reflectiveContexts = [...new Set(storylines.reflectiveContexts)];
                    storylines.clinicalKeywords = [...new Set(storylines.clinicalKeywords)];
                    storylines.impacts = [...new Set(storylines.impacts)];
                    storylines.barriers = [...new Set(storylines.barriers)];
                    storylines.suggestedPathways = [...new Set(storylines.suggestedPathways)].slice(0,Math.min(3,storylines.suggestedPathways.length));
                    //
                    // text based highlight summary
                    var highlightSummary = `Your 2024 learning journey:\nTotal credits earned: ${storylines.totalCredits}\nReflective contexts: ${storylines.reflectiveContexts.join(', ')}\nImpacts: ${storylines.impacts.join(', ')}\nBarriers: ${storylines.barriers.join(', ')}\nSuggested Learning Pathways: ${storylines.suggestedPathways.join(', ')}`;
                    //
                    FetchOrGenerate2024Recap(highlightSummary);
                    //showValidationModal('Your 2024 recap', `Here's a quick recap of your learning journey from 2024.<br><br><ul class="list-disc list-inside"><li>Total credits earned: ${storylines.totalCredits}</li><li>Reflective contexts: ${storylines.reflectiveContexts.join(', ')}</li><li>Impacts: ${storylines.impacts.join(', ')}</li><li>Barriers: ${storylines.barriers.join(', ')}</li><li>Suggested Pathways: ${storylines.suggestedPathways.join(', ')}</li></ul>`, 'Post on LinkedIn', ()=>{performTask('validationmodal-dismiss');}, 'Close', ()=>{performTask('validationmodal-dismiss');});
                }
            });
        }else{
            showValidationModal('⚠️ Only available to subscribers ⚠️', `Unwrap insights from your 2024 reflective learning journey - and share with peers, leadership and more. Plus, earn 3 more credits for reflecting on your 2024 learnings.`,"Subscribe now",()=>{
                mixpanel.track('Click to Subscribe for 2024 Recap', {CURR_STRIPEPACKET});
                analytics.track('learnerplus_click_to_subscribe_for_2024_recap', {CURR_STRIPEPACKET});
                performTask("start-subscription");
            }, "Not now", null);    
        }

    }

    function FetchOrGenerate2024Recap(highlightsText){
        firebase.firestore().collection('narratives').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).doc("recap-2024").get().then((doc)=>{
            if (doc.exists){
                // show the narrative
                var recapPacket = doc.data().recap_2024;
                var reflectiveTopics = doc.data().build_on_topics;
                var reflectionLinkHtml = "Reflect on your 2024 highlights and earn more credit:<br/>" + reflectiveTopics.map(x=>`<a data-task="paidlink-reflection::/nudge-reflection?ipoc_secret=subscribersonly-recap2024&ipoc_context=Reflective%20Learning%20Moment&reflectionkit=reflective-practice&tracking_id=recap2024&ipoc_keyword=${encodeURI(x)}" target="_blank" class="ui-action-btn cursor-pointer font-semibold text-blue-500 hover:underline">Reflect on ${x}</a>`).join("<br/>");
                return showValidationModal('Your Reflective Journey | Unwrapped', `<div id="learning-narrative">${recapPacket}</div><br/>${reflectionLinkHtml}`, 'Copy to Clipboard', ()=>{            
                    navigator.clipboard.writeText(document.getElementById('learning-narrative').innerText).then(() => {
                        showNotification("Insights copied", "Your insights have been copied to your clipboard.", "success", 3600);
                        performTask('validationmodal-dismiss');
                    }).catch(err => {
                        console.error('Could not copy text: ', err);
                    });
                }, 'Close', ()=>{performTask('validationmodal-dismiss');});
            }else{
                // we should generate the narrative and save it
                var payloadForNarrative = {
                    highlights: highlightsText
                };
                //
                showLoadingOverlay("Generating your 2024 recap...");
                axios.post(`/reflective-practice-generate-unwrapped`, payloadForNarrative)
                    .then((response) => {
                        console.log(response.data);
                        hideLoadingOverlay();
                        // save the narrative here and present it;
                        console.log("save the narrative and show it");
                        //
                        firebase.firestore().collection('narratives').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).doc("recap-2024").set(response.data.validation).then(()=>{
                            var recapPacket = response.data.validation.recap_2024;
                            var reflectiveTopics = response.data.validation.build_on_topics;
                            var reflectionLinkHtml = "Reflect on your 2024 highlights and earn more credit:<br/>" + reflectiveTopics.map(x=>`<a href="/nudge-reflection?ipoc_secret=jskjm-recap2024&ipoc_context=Reflective%20Learning%20Moment&reflectionkit=reflective-practice&tracking_id=recap2024&ipoc_keyword=${encodeURI(x)}" target="_blank" class="font-semibold text-blue-500 hover:underline">Reflect on ${x}</a>`).join("<br/>");
                            return showValidationModal('Your Reflective Journey | Unwrapped', `<div id="learning-narrative">${recapPacket}</div><br/>${reflectionLinkHtml}`, 'Copy to Clipboard', ()=>{
                                navigator.clipboard.writeText(document.getElementById('learning-narrative').innerText).then(() => {
                                    showNotification("Insights copied", "Your insights have been copied to your clipboard.", "success", 3600);
                                    performTask('validationmodal-dismiss');
                                }).catch(err => {
                                    console.error('Could not copy text: ', err);
                                });
                            }, 'Close', ()=>{performTask('validationmodal-dismiss');});
                        }).catch((error)=>{
                            console.error('Error saving narrative: ', error);
                            showNotification("Narrative generation error. Please try again later!", error, "",5400);
                        });;
                    })
                    .catch((error) => {
                        console.error('Error generating narrative ', error);
                        showNotification("Narrative generation error. Please try again later!", error, "",5400);
                        hideLoadingOverlay();
                    });

            }
        }).catch((error)=>{
            showNotification("Narrative fetch error", error, "",5400);
        });
    }

    // UI Utility Functions
    function selectDropdownOption(dropdownId, valueToSelect) {
        let selectElement = document.getElementById(dropdownId);
        selectElement.value = valueToSelect; // This will make the browser select the option that has this value
        
        // Optionally, trigger any 'change' event listeners attached to the dropdown
        selectElement.dispatchEvent(new Event('change'));
    }

    function showValidationModal(modalTitle, modalContent, modalActionLabel, modalActionCallback, cancelLabel, modalCancelCallback){
        document.getElementById('validationmodal-title').innerHTML = modalTitle;
        document.getElementById('validationmodal-content').innerHTML = modalContent;
        document.getElementById('validationmodal-action').innerHTML = modalActionLabel;
        if (cancelLabel === ''){
            document.getElementById('validationmodal-cancelbtn').classList.add('hidden');
        }else{
            document.getElementById('validationmodal-cancelbtn').classList.remove('hidden');
        }
        document.getElementById('validationmodal-cancelbtn').innerHTML = cancelLabel || "Cancel";
        document.getElementById('validationmodal-actionbtn').onclick = modalActionCallback;
        if (modalCancelCallback){
            document.getElementById('validationmodal-cancelbtn').onclick = modalCancelCallback;
        }else{
            document.getElementById('validationmodal-cancelbtn').onclick = ()=>{
                performTask('validationmodal-dismiss');
            };
        }
        //
        document.getElementById('validationmodal').classList.remove('hidden');
        document.getElementById('validationmodal-content').parentNode.scrollTo({top: 0, behavior: 'smooth'});
        //
    }

    var notificationTimeout = false;
    function showNotification(notificationTitle, notificationContent, notificationType, notificationDuration){
        document.getElementById('appnotifier-title').innerHTML = notificationTitle;
        document.getElementById('appnotifier-content').innerHTML = notificationContent;
        document.getElementById('appnotifier').classList.remove('opacity-0', 'scale-95', 'hidden');
        document.getElementById('appnotifier').classList.add('opacity-100', 'scale-100');
        notificationTimeout = setTimeout(function(){
            performTask('appnotifier-dismiss');
        }, notificationDuration);
    }

    function showLoadingOverlay(loadingMessage){
        if (loadingMessage){
            document.getElementById('axios_loading_message').innerHTML = loadingMessage
        }
        document.getElementById('axios_loading_overlay').classList.remove('hidden');

    }

    function hideLoadingOverlay(){
        document.getElementById('axios_loading_overlay').classList.add('hidden');
    }

    function UI_BindActions(){
        // Show the overlay when a request starts
        axios.interceptors.request.use(function (config) {
            showLoadingOverlay();
            return config;
        }, function (error) {
            hideLoadingOverlay()
            return Promise.reject(error);
        });

        // Hide the overlay when a response is received
        axios.interceptors.response.use(function (response) {
            hideLoadingOverlay()
            return response;
        }, function (error) {
            hideLoadingOverlay();
            return Promise.reject(error);
        });
        //

        document.querySelector('#current-tab-selector').addEventListener('change', (event)=>{
            document.querySelectorAll('.hubtab-btn').forEach(element => {
                element.classList.remove('border-indigo-500','text-indigo-600');
                element.classList.add('border-transparent','text-gray-500');
            })
            document.querySelectorAll('.learnerhub-section').forEach(element => {
                element.classList.add('hidden');
            });
            document.getElementById(document.querySelector('#current-tab-selector').value).classList.remove('hidden');
        });

        document.getElementById('learnerplus').addEventListener('click', function(event) {
            // Check if the clicked element has the class 'ui-action-btn'
            if (event.target.classList.contains('ui-action-btn')) {
              event.preventDefault();
              var uiTask = event.target.getAttribute('data-task');
              //
              if (uiTask.indexOf("showtab-") !== -1){
                var tabBtnClicked = event.target;
                // default the other tabs and sections to hidden
                document.querySelectorAll('.hubtab-btn').forEach(element => {
                    element.classList.remove('border-indigo-500','text-indigo-600');
                    element.classList.add('border-transparent','text-gray-500');
                })
                document.querySelectorAll('.learnerhub-section').forEach(element => {
                    element.classList.add('hidden'); 
                });
                // now lets activate the right guys
                var elemToShow = uiTask.split("showtab-")[1];
                selectDropdownOption('current-tab-selector', elemToShow);
                document.getElementById(elemToShow).classList.remove('hidden');
                tabBtnClicked.classList.add('border-indigo-500','text-indigo-600')
                tabBtnClicked.classList.remove('border-transparent','text-gray-500');
                return;
              }
              //
              performTask(uiTask);
            }
        });

        document.getElementById('appnotifier-close-btn').addEventListener('click', function(event) {
            performTask('appnotifier-dismiss');
        });
    }

    function performTask(uiTask){
        //
        if (uiTask.indexOf("generate-certificate::") === 0){
            var certificateId = uiTask.split("generate-certificate::")[1];
            return GenerateAndDownloadCertificate(certificateId);
        }
        if (uiTask.indexOf("genereate-moc-cert::") === 0){
            var certificateId = uiTask.split("genereate-moc-cert::")[1];
            return GenerateAndDownloadMOCCertificate(certificateId);
        }
        if (uiTask.indexOf("launch-or-generate-narrative::") === 0){
            var certificateId = uiTask.split("launch-or-generate-narrative::")[1];
            return GenerateOrShowNarrative(certificateId);
        }
        if (uiTask.indexOf("launch-cpd-agents::") === 0){
            var certificateId = uiTask.split("launch-cpd-agents::")[1];
            return ShowCPDAgents(certificateId);
        }
        if (uiTask.indexOf("perform-cpd-agent-task::") === 0){
            var certificateId = uiTask.split("perform-cpd-agent-task::")[1].split("~")[0];
            var cpdAgentTaskId = uiTask.split("perform-cpd-agent-task::")[1].split("~")[1];
            //
            return GenerateOrShowCPDCopilotNarrative(certificateId, cpdAgentTaskId);
        }
        if (uiTask.indexOf("launch-transcript::") === 0){
            var certificateId = uiTask.split("launch-transcript::")[1];
            return LaunchLearnerPlusTranscript(certificateId);
        }
        //
        if (uiTask.indexOf("paidlink-reflection::") === 0){
            var reflectionLinkForSubscribers = uiTask.split("paidlink-reflection::")[1];
            if (CURR_STRIPESTATUS){
                return window.open(reflectionLinkForSubscribers);
            }else{
                return window.open('/reflective-practice');
            }
        }
        //
        if (uiTask === "email-affiliate-link"){
            return window.open(`mailto:?subject=Join me on Learner%2B&body=Hi,%0A%0AI've been using Learner%2B to reflect anytime and unlock CME%2FCE credits and I think you might find it useful. Here's a link to get started: ${encodeURIComponent(CURR_USERPROFILE.rewardful_affiliate.links[0].url)} %0A%0ABest regards,%0A[Your Name]`);
        }
        if (uiTask === "text-affiliate-link"){
            return window.open(`sms:?body=Hi, I've been using Learner+ and I think you might find it useful. Here's a link to get started: ${CURR_USERPROFILE.rewardful_affiliate.links[0].url}`);
        }
        if (uiTask.indexOf("learn-more-why-subscribe") === 0){
            return showValidationModal('Why Subscribe to Learner+?', `<ul class="list-disc list-inside font-semibold text-left">
                    <li>Reflect ANYTIME &amp; EVERYWHERE learning happens</li>
                    <li>Launch in your browser or via WhatsApp/SMS</li>
                    <li>Invite reflect@learner.plus to calendar events</li>
                    <li>Unlock unlimited CME/CE credits &amp; certificates</li>
                    <li>Earn and auto-report MOC for <a class="text-bold text-pink-700" href="https://reflective.credit/activities/ReflectivePractice_ADP_CreditInfo_JSKJM.pdf" target="_blank">8 Specialty boards ↗</a></li>
                    <li>Simplify reimubursements with detailed transcripts</li>
                    <li>Get CPD / Appraisal-friendly Insights</li>
                    <li>and more <strong class="font-bold text-learner-blue">for just $50/year</strong></li>
                </ul>→ PS: Your employer may reimburse this subscription under your CME/CE budget!`, 
                `Subscribe Now`,()=>{
                    performTask('smart-manage-subscription');
                }, 
                'Not now', null);
        }
        //
        switch (uiTask){
            case "show-cpd-info":
                showValidationModal('About the credits', 'Learner+ certificates display <i>AMA PRA Category 1 Credits™</i>, valid for UK CPD requirements. Ensure your reflections align with your practice and use certificates, transcripts, and CPD Copilot results to document your portfolio.<br/><a href="https://cpduk.co.uk/courses/learner-by-cmefy-learner-reflective-practice" target="_blank" class="block mt-3 cursor-pointer"><img class="w-24 h-auto" src="'+cpdImagePath+'"></a>', 'Close', ()=>{
                    performTask('validationmodal-dismiss');
                }, 'View CME/CE Info', ()=>{
                    window.open('https://reflective.credit/activities/ReflectivePractice_ADP_CreditInfo_JSKJM.pdf', '_blank');
                    performTask('validationmodal-dismiss');
                });
                break;
            case "launch-copilot-suite":
                showValidationModal('About CPD Copilot', 'Generate your certificate to unlock Copilot features in Learner+. These features can instantly enhance your CPD workflows, streamline appraisals, simplify career development reporting, and more.', 'Close', ()=>{performTask('validationmodal-dismiss');}, '', null);
                //window.location.href = '/reflective-copilot';
                break;
            case "connect-fourteen-fish":
                if (CURR_USERPROFILE.fourteenfish){
                    showValidationModal(`FourteenFish Portfolio is connected!`, `Once you generate your certificate, you can report your earned CPD credits via the Transcript functionality.`, 'Close', ()=>{performTask('validationmodal-dismiss');}, '', null);
                }else{
                    window.location.href = '/auth/fourteenfish-login';
                }
                break;
            case "fourteenfishsmartsync":
                FetchDeepLinkAndSyncWithFourteenFish();
                break;
            case "launch-copilot-guides":
                showValidationModal('Coming Soon: Reflective Guides™', 'Learner+ Reflective Guides™ help you explore ideas, breakdown concepts and receive topic-specific feedback from your trusted sources to spark your reflective learning (and CME/CE/CPD) journey.', 'Close', ()=>{performTask('validationmodal-dismiss');}, '', null);
                break;
            case "unwrap-2024":
                return GenerateOrShowRecap(1704067200000, 1735689599000);
                break;
            case "moc-management":
                document.getElementById('toggleButton').click();
                // delay this for 1 second
                setTimeout(()=>{
                    document.getElementById('moc_section')?.scrollIntoView({ behavior: 'smooth' });
                }, 1400);
                break;
            case "start-default-reflection":
                window.location.href = '/'+CURR_REFLECTIONKIT;
                break;
            case "launch-notebookv2":
                console.log(CURR_USERPROFILE.credential);
                if (CURR_USERPROFILE.credential === "General Practitioner Trainee"){
                    window.location.href= '/notebook-gptrainees'; // persona specific
                }else{
                    window.location.href= '/reflective-notebook'; // was /notebook
                }
                break;
            case "launch-notepad":
                window.location.href= '/reflective-notebook';
                break;
            case "launch-calendar":
                showValidationModal('Invite Learner+', `Invite <b class="text-learner-blue font-bold underline">reflect@learner.plus</b> to your meetings and automatically get reminded to reflect!`, 'Copy to Clipboard', ()=>{
                    navigator.clipboard.writeText('reflect@learner.plus').then(() => {
                        showNotification("Learner+ Contact copied", "The email address has been copied to your clipboard - save them to your contacts and/or invite them to your meetings!", "success", 3600);
                        performTask('validationmodal-dismiss');
                    }).catch(err => {
                        console.error('Could not copy text: ', err);
                        performTask('validationmodal-dismiss');
                    });
                }, 'Done', null);
                break;
            case "launch-whatsapp":
                var whatsAppLink = 'https://wa.me/message/NKTISGGV6UB7B1';
                var whatsAppQR = LearnerPlusWhatsAppQRCode;
                if (CURR_USERPROFILE.credential === "General Practitioner Trainee"){
                    whatsAppLink = 'https://wa.me/message/W3EOZ5ATX6LFO1';
                    whatsAppQR = LearnerPlusWhatsAppQRCode_GPTrainees;
                }
                showValidationModal('Reflect via WhatsApp', `On the go? At a conference? Using in-flight wifi? Chat with Learner+ on WhatsApp to jot notes, capture ideas and ask questions. Learner+ uses your notes to prompt reflections so you can earn credits instantly. <br/><br/>Scan the QR below to start a conversation or click Launch WhatsApp to get started!<br/><br/><div class="flex items-center justify-center"><img src="${whatsAppQR}" class="rounded-md shadow-lg hover:border hover:border-blue-500 hover:border-4" style="width:144px; height:144px;"></div>`, 
                    'Launch WhatsApp', ()=>{
                    window.open(whatsAppLink, '_blank');
                    performTask('validationmodal-dismiss');
                }, 'Done', null);
                break;
            case "show-all-reflections":
                showNotification("Subscribe to Learner+", `You must be a subscriber to view your full reflection history<br/><br/><button type="button" class="rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 ui-action-btn" data-task="start-subscription">Subscribe Now</button>`, "info", 6300);
                break;
            case "prompt-certificatepacket-generation":
                var askCertClaim = false;
                var askCertClaimMessage = ``
                if (!askCertClaim && CURR_LEARNERSTATE.creditBalance <= 3){
                    askCertClaimMessage = `The number of credits you claim will appear on your certificate. Do you want to claim now or wait until you’ve reflected more? (You cannot combine certificates afterward).`
                    askCertClaimMessage = `By claiming credits, Learner+ will convert your earned credits into a downloadable certificate. <strong>You may prefer to continue earning credits and claim them all at a later date.</strong>`;
                    askCertClaim = true;
                }
                if (!askCertClaim && [...new Set(CURR_LEARNERSTATE.reflections.map(r=>r.ipoc_context))].length === 1 && CURR_LEARNERSTATE.creditBalance <= 5){
                    askCertClaimMessage = `Are you sure you're done reflecting on all the ${[...new Set(CURR_LEARNERSTATE.reflections.map(r=>r.ipoc_context))][0]} learning moments for now? Click claim now or wait until you've reflected more.`
                    askCertClaim = true;
                }
                if (askCertClaim){
                    showValidationModal('Are you sure you want to claim this credit now?', askCertClaimMessage, `Claim ${CURR_LEARNERSTATE.reflections.length} credit${CURR_LEARNERSTATE.reflections.length>1?'s':''}`,
                    function(){
                        performTask("validationmodal-dismiss");
                        LaunchPrecertEvaluationFlow();
                    }, 
                    'Not now', 
                    null);
                }else{
                    LaunchPrecertEvaluationFlow();
                }
                break;
            case "update-learner-profile":
                updateProfile((updateError)=>{
                    console.log(`profile updated`, updateError);
                    //
                    window.location.reload();
                });
                break;
            case "update-mocprofile":
                updateMOCToUserProfile((updateStatus)=>{
                    if (updateStatus){
                        FB_UpdateProfile((updateError)=>{
                            if (updateError){
                                showNotification('MOC Profile Update Error', 'There was an error updating your MOC profile.', 'error', 3000);
                            }else{
                                // no error, we should refresh life
                                window.location.reload();
                            }
                        });
                    }else{
                        showNotification('MOC Profile Update Error', 'There was an error updating your MOC profile.', 'error', 3000);
                    }
                });
                break;
            case "update-cebrokerprofile":
                updateCEBToUserProfile((updateStatus)=>{
                    if (updateStatus){
                        FB_UpdateProfile((updateError)=>{
                            if (updateError){
                                showNotification('Profile Update Error', 'There was an error updating your CE Broker profile.', 'error', 3000);
                            }else{
                                // no error, we should refresh life
                                window.location.reload();
                            }
                        });
                    }else{
                        showNotification('CE Broker Profile Update Error', 'There was an error updating your CE Broker profile.', 'error', 3000);
                    }
                });
                break;
            case "info-moc":
                var myBoards = [];
                for (var board in CURR_USERPROFILE.moc.moc_boards){
                    if (CURR_USERPROFILE.moc.moc_boards[board]){
                        myBoards.push(board.toUpperCase());
                    }
                }
                return showValidationModal('Your MOC Credits have been reported!', "Your MOC credits ("+myBoards.join(", ")+") have been successfully reported to ACCME’s CME Passport. You can view your learning portfolio on your transcript in the Learner+ dashboard.",
                    `Got it`, ()=>{
                        performTask('validationmodal-dismiss');
                    }, 
                    '', null);
    
                break;
            case "text-me":
                axios.get('/tasks/text-me', {
                    params: {
                        uid: CURR_USER.uid,
                        message: '👋 Hi, this is Learner+! You can text this number to spark a reflection anytime :). Just say "Hi"'
                    }
                })
                break;
            case "smart-manage-subscription":
                if (CURR_STRIPEPACKET.subscription_status === 'active'){
                    mixpanel.track('Manage Subscription', {CURR_STRIPEPACKET});
                    analytics.track('learnerplus_manage_subscription', {CURR_STRIPEPACKET});
                    performTask('manage-subscription');
                }else{
                    mixpanel.track('Click to Subscribe', {CURR_STRIPEPACKET});
                    analytics.track('learnerplus_click_to_subscribe', {CURR_STRIPEPACKET});
                    performTask('start-subscription');
                }
                break;
            case "start-subscription":
                // check if the subscription is active, otherwise take them to the subscription page
                window.location.href = '/create-checkout-session?email='+CURR_USERPROFILE.email+'&uid='+CURR_USER.uid+'&primarylocation='+encodeURIComponent(CURR_USERPROFILE.primarylocation);
                break;
            case "manage-subscription":
                window.location.href = '/manage-subscription?customer='+CURR_STRIPEPACKET.customer + "&subscription="+CURR_STRIPEPACKET.subscription + "&uid="+CURR_USER.uid;
                break;
                break;
            case "validationmodal-dismiss":
                // unbind and close
                document.getElementById('validationmodal-actionbtn').onclick = null; 
                document.getElementById('validationmodal-cancelbtn').onclick = null; 
                document.getElementById('validationmodal').classList.add('hidden');
                break;
            case "appnotifier-dismiss":
                clearTimeout(notificationTimeout);
                document.getElementById('appnotifier').classList.remove('opacity-100', 'scale-100');
                document.getElementById('appnotifier').classList.add('opacity-0', 'scale-95', 'hidden');
                break;
            case "logout-curruser":
                if (confirm('Are you sure you want to logout?')){
                    firebase.auth().signOut().then(() => {
                        analytics.reset();
                        location.reload();
                    }).catch((error) => {
                        // An error happened.
                        showNotification('Logout Error', 'There was an error logging out - '+error+'.', 'error', 3000);
                    });
                }
                break;
            default:
                // no-op
                break;
        }
        
    }
    //
    const setSelectValue = (paramValue, selectElementId) => {
        const selectElement = document.getElementById(selectElementId);
        if (selectElement) {
            const option = selectElement.querySelector(`option[value="${paramValue}"]`);
            if (option) {
                selectElement.value = paramValue;
            }
        }
    };

    function convertMarkdownToHTML(markdownText) {
        if (markdownText.indexOf("##REFLECTION_DATASET##") !== -1){
            return `Your reflections lack the relevance and depth needed to fulfill the requirements of this specific Copilot task.`;
        }
        var cleanedUpHtml = markdownText.replace(/\n/g, '');
        cleanedUpHtml = cleanedUpHtml.replace(/<ul>/g, '<ul class="list-disc list-inside">');
        return cleanedUpHtml;
        // Escape any existing HTML to prevent injection attacks
        let html = markdownText
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    
        // Convert headings
        html = html
            .replace(/^### (.*)$/gm, '<h3>$1</h3>')
            .replace(/^## (.*)$/gm, '<h2>$1</h2>')
            .replace(/^#### (.*)$/gm, '<h4>$1</h4>');
    
        // Convert bold and italic text
        html = html
            .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
            .replace(/\*(.*?)\*/g, '<em>$1</em>');
    
        // Convert list items (supports nested lists)
        html = html.replace(/^- (.*)$/gm, '<li>$1</li>');
        html = html.replace(/(<li>.*<\/li>\n?)+/gm, match => `<ul>${match.trim()}</ul>`);
    
        // Replace standalone line breaks with paragraphs
        html = html.replace(/\n{2,}/g, '</p><p>'); // Convert double line breaks to paragraph breaks
        html = html.replace(/\n/g, '<br>'); // Convert single line breaks to <br>
    
        // Wrap the entire content in a <p> block if it's not part of a list or heading
        html = `<p>${html}</p>`.replace(/<\/ul>\n<p>/g, '</ul>'); // Clean up <p> after lists
        html = html.replace(/<p>\s*<\/p>/g, ''); // Remove empty paragraphs
    
        // Return the formatted HTML
        return html.trim();
    }
    

})();
